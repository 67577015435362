





















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getForms, updateFormStatusById, updateFormTypeById } from '@/api/leadplus/forms'
import CreateFormModal from '@/modules/leadplus/components/CreateFormModal/Index.vue'
import VsFogFormPreviewModal from '@/modules/leadplus/components/VsFogFormPreviewModal/Index.vue'
import VsFormEditor from '@/modules/leadplus/components/VsFormEditor/Index.vue'
import { LeadplusForm } from '@/api/leadplus/types'
import { CustomField } from '@/utils/customFields'
import { getListCustomFields } from '@/api/consoleApi/recipients'

@Component({
    name: 'LeadplusModalV2',
    components: {
        CreateFormModal,
        VsFogFormPreviewModal,
        VsFormEditor,
    },
})
export default class extends Vue {
    private formId = ''
    private leadplusTarget: any = null
    private loading = false
    private currentLeadplusForm = ''
    private forms: any[] = []
    private items: any[] = []
    private open = false
    private createdFormId = ''
    private campaignId = ''
    private consoleFormId = ''
    private responseMessage = ''
    private showAlertFormNotExist = false
    customFields: CustomField[] = []

    public close (): void {
        this.open = false
    }

    public async openModal (formId: string, leadplusTarget: any): Promise<void> {
        this.formId = formId
        this.leadplusTarget = leadplusTarget
        this.showAlertFormNotExist = false
        this.campaignId = ''
        this.consoleFormId = ''
        this.createdFormId = ''
        this.getEmbeddedForms()
        this.open = true
    }

    get leadplusFormNotExist () {
        return (
            !this.loading &&
            !this.form
        )
    }

    get form () {
        return this.forms.find((el: any) => el._id === this.formId)
    }

    private async getEmbeddedForms (): Promise<void> {
        this.loading = true
        const forms: any[] = []
        try {
            await this.loopCall(0, forms)
            this.forms = forms
            this.items = this.forms.map((el: any) => {
                return {
                    label: el.name,
                    value: el._id,
                }
            })
        } catch (e) {
            this.forms = []
            this.items = []
        }
        this.loading = false
        this.showAlertFormNotExist = !!(this.formId && this.leadplusFormNotExist)
    }

    generateQuery (page: number) {
        const query: any = {
            limit: 100,
            skip: 100 * page,
            sort: '-_id',
        }

        const filter: any = {
            $and: [],
        }

        filter.$and.push({
            'theme.type': 'embedded',
        })

        if (filter.$and.length > 0) {
            query.filter = JSON.stringify(filter)
        }
        return query
    }

    async loopCall (page: number, values: any[]) {
        if (page > 10) return
        const resp = await getForms(this.generateQuery(page))
        values.push(...resp.data.docs)
        if (resp.data.docs.length < 100) return
        await this.loopCall(page + 1, values)
    }

    private async changeleadplusForm () {
        if (!this.formId) return
        if (!this.leadplusTarget) {
            this.$emit('notarget-error')
            return
        }

        const id = this.leadplusTarget.getId()
        const form = this.leadplusTarget.find(`#${id} .fogForm`)

        form[0].setAttributes({ 'data-form': this.formId })
        form[0].setId('fogFormEmbedded_' + this.formId)
        this.leadplusTarget.setAttributes({ 'data-form-id': this.formId })
        this.close()
    }

    async formCreated (form: LeadplusForm) {
        this.formId = form._id
        await this.setFormType('embedded')
        await this.setActive(true)
        await this.getEmbeddedForms()
    }

    async setFormType (formType: string) {
        try {
            await updateFormTypeById(this.formId, { type: formType })
        } catch (e) {
            console.log(e)
        }
    }

    async setActive (isActive: boolean) {
        try {
            await updateFormStatusById(this.formId, {
                isActive,
            })
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('form.foreign_recipient_id')
    private async getCustomFields () {
        if (!this.form) return
        try {
            const cf: any[] = []
            await this.loopCustomFieldsCall(1, cf)
            this.customFields = cf
            // this.loadForm()
        } catch (e) {
            console.log(e)
            this.customFields = []
        }
    }

    async loopCustomFieldsCall (page: number, values: any[]) {
        const resp = await getListCustomFields(this.form?.foreign_recipient_id || '', {
            page,
            limit: 100,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values)
        }
    }
}
